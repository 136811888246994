import { Provider } from '@angular/core';
import { environment } from '@environments/environment';
import { PriceSegmentationFieldsService } from '@modules/components/available-fields/services/price-segmentation-fields/price-segmentation-fields.service';
import { PriceSegmentationFormService } from '@modules/components/available-fields/services/price-segmentation-form/price-segmentation-form.service';
import { withAvailableFieldForm, withAvailableFields } from '@modules/components/available-fields/services/provider';
import { DOWNLOADER_URL, UPLOADER_URL } from '@modules/directives/file-uploader/uploader-url.token';
import { PriceSegmentationViewPipe } from '@permissions/price-segmentation-view/price-segmentation-view.pipe';
import { ActiveCategory } from '@services/category/category';
import { TimerService } from '@services/timer/timer.service';
import { CATEGORY } from '@tokens/category.token';
import { withSelectionModelType } from '@tokens/selection-model.token';
import { withSettingsKeyByCategory } from '@type/user-settings';
import { PriceSegmentApiService } from './services/price-segment-api/price-segment-api.service';
import { PriceSegmentMassUpdateService } from './services/price-segment-mass-update/price-segment-mass-update.service';
import { SegmentationDictionaryService } from './services/segmentation-dictionary/segmentation-dictionary.service';

const createBaseUrl = (categoryId: number, action: 'import' | 'export') =>
  `${environment.apiUrl}categories/${categoryId}/price_segmentation/${action}`;

export const SEGMENTATION_UPLOADER_PROVIDERS = [
  {
    provide: DOWNLOADER_URL,
    useFactory: (category: ActiveCategory) => () => createBaseUrl(category.value()!.id, 'export'),
    deps: [CATEGORY],
  },
  {
    provide: UPLOADER_URL,
    useFactory: (category: ActiveCategory) => () => createBaseUrl(category.value()!.id, 'import'),
    deps: [CATEGORY],
  },
];

export const PRICE_SEGMENTATION_PROVIDER: Provider[] = [
  withAvailableFields(PriceSegmentationFieldsService),
  withAvailableFieldForm(PriceSegmentationFormService),
  withSettingsKeyByCategory('priceSegmentation'),
  PriceSegmentApiService,
  TimerService,
  SegmentationDictionaryService,
  PriceSegmentMassUpdateService,
  withSelectionModelType('byId'),
  SEGMENTATION_UPLOADER_PROVIDERS,
  PriceSegmentationViewPipe,
];
